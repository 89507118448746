<template>
    <div class="container pd100 mb20 appointment_schedule" v-wechat-title="$route.meta.title=times" >
        <!-- <schedule v-model="active" @change="onScheduleChange"></schedule> -->
        <!-- <van-notice-bar v-if="!appointmentClose" left-icon="volume-o" @close="handleClose" color="#1989fa" background="#ecf9ff" mode="closeable">每周日晚上七点，开放下周课程预约，不限请假次数，请假不扣课时。</van-notice-bar> -->
        <!-- <van-sticky> -->
            <vue-hash-calendar 
                ref="calendar"
                weekStart='monday'
                @click="handleClick"
                @change="handleChange"
                @slidechange="handleSlidechange"
                checkedDayClassName="schedule-calendar-color"
                format="YY-MM-DD"
                :defaultDatetime="defaultDatetime"
                :visible="true" 
                :isShowArrow="false" 
                :isShowAction="false" 
                :scrollChangeDate="true"
                :markDate="markDate"
                :isShowWeekView="isShowWeekView">
                <!-- 
                :minDate="minDate"
                :maxDate="maxDate"
                -->
                <!-- <div @click="isShowWeeks" slot="arrow">
                <img v-if="isShowWeekViews" src="@/assets/download.png">
                <img v-else src="@/assets/download-2.png">
                </div> -->
            </vue-hash-calendar>
        <!-- </van-sticky> -->

        <div class="campus_schedule_search">
            <van-cell-group>
                <van-cell @click="showDropStudio = true" title="校区" is-link :value="studioValue.studio_name" />
                <van-cell @click="showTeacher = true" title="老师" is-link :value="teacherValue.filter_name" />
                <!-- <van-cell @click="showTeacher = true" title="上课老师" is-link :value="teacherValue.filter_name" /> -->
                <van-cell title="时间">
                    <van-checkbox-group checked-color="#00cca2" @change="handleCheckbox" v-model="checkboxGroup" direction="horizontal">
                        <van-checkbox name="forenoon" shape="square" icon-size="18">上午</van-checkbox>
                        <van-checkbox name="afternoon" shape="square" icon-size="18">下午</van-checkbox>
                        <van-checkbox name="evening" shape="square" icon-size="18">晚上</van-checkbox>
                    </van-checkbox-group>
                </van-cell>
                <van-cell title="级别">
                    <van-checkbox-group checked-color="#00cca2" @change="handleCheckbox" v-model="checkboxLevelGroup" direction="horizontal">
                        <van-checkbox name="L1" shape="square" icon-size="18">L1</van-checkbox>
                        <van-checkbox name="L2" shape="square" icon-size="18">L2</van-checkbox>
                        <van-checkbox name="L3" shape="square" icon-size="18">L3</van-checkbox>
                        <van-checkbox name="L4" shape="square" icon-size="18">L4</van-checkbox>
                    </van-checkbox-group>
                </van-cell>
            </van-cell-group>
        </div>
        <!-- <div class="mb10" style="position:relative;z-index: 1">
            <div class="section-row">
                <div class="section-studio" @click="showDropStudio = true">
                    <span class="van-ellipsis">{{studioValue.studio_name}}</span>
                    <van-icon name="arrow-down" color="#666" />
                </div>
                <div class="section-teacher" @click="showTeacher = true">
                    <span class="van-ellipsis">{{teacherValue.filter_name}}</span>
                    <van-icon name="arrow-down" color="#666" />
                </div>
                <div class="section-age" @click="showAge = true">
                    <span class="van-ellipsis">{{ageValue.label}}</span>
                    <van-icon name="arrow-down" color="#666" />
                </div>
            </div>
        </div> -->
        <div style="margin-bottom:45px;">
            <div v-for="(it,ind) in courses" :label="`${it.course_name}`" :key="ind" class="appointment_schedule_item" @click="course_book(it)">
                <van-cell-group @click="toPath(it.course_class_id,it)">
                    <van-cell :value="it.teacher">
                        <template #title>
                            <span style="font-weight: bold;">{{`${it.course_name} ${it.class_time}`}}</span>
                        </template>
                        <template #label>
                            <van-tag plain type="success" v-if="it.reserve_count<5">{{it.reserve_count}}/{{it.table_count}}</van-tag>
                            <van-tag plain type="warning" v-if="it.reserve_count>=5 && it.reserve_count<8">{{it.reserve_count}}/{{it.table_count}}</van-tag>
                            <van-tag plain type="danger" v-if="it.reserve_count>=8">{{it.reserve_count}}/{{it.table_count}}</van-tag>
                            <van-tag plain type="success" style="margin-left: 5px;display: inline-block;word-break: keep-all;overflow: hidden;text-overflow: ellipsis;" v-for="(label_name,index) in it.course_label" :key="index">{{label_name}}</van-tag>
                            <!-- <van-tag plain type="success" style="margin-left: 5px;display: inline-block;word-break: keep-all;overflow: hidden;text-overflow: ellipsis;">{{it.room_name}}</van-tag> -->
                            <!-- <span class="custom-title">{{`${it.class_name} ${it.room_name}`}}</span> -->
                        </template>
                        
                    </van-cell>
                    
                    <div v-if="it.discount_name && it.course_can_book" class="appointment_schedule_item_mark">
                        <img src="@/assets/mark.png" alt="">
                        <div v-if="it.discount_name.length<3" class="appointment_schedule_item_mark_text">{{it.discount_name}}</div>
                        <div v-else class="appointment_schedule_item_mark_textF">{{it.discount_name}}</div>
                    </div>

                    <div v-else-if="!it.course_can_book" class="appointment_schedule_item_mark">
                        <img src="@/assets/mark_blue.png" alt="">
                        <div class="appointment_schedule_item_mark_textF">{{it.class_type}}</div>
                    </div>
                </van-cell-group>
                <!-- <van-progress :percentage="100" :show-pivot="false" color="#00cca2" stroke-width="12" /> -->
                <!-- <van-tag type="success" v-if="it.course_can_book" class="appointment_schedule_item_btn">去约课</van-tag> -->
                <van-tag type="success" v-if="it.course_can_book && it.reserve_count<it.table_count" class="appointment_schedule_item_btn">去约课</van-tag>
                <van-tag type="warning" v-if="it.course_can_book && it.reserve_count >=it.table_count" class="appointment_schedule_item_btn">等位({{it.course_waitno}})</van-tag>
                <!-- <van-button size="mini" v-if="it.course_can_book" class="appointment_schedule_item_btn" @click="toPath(it.course_class_id)" type="primary">约课</van-button> -->
            </div>
        </div>
        <van-empty v-if="courses.length == 0 && !loading" description="暂无相关数据~" />
        <van-popup v-model="showStudio" position="bottom" round>
            <van-picker
                show-toolbar
                value-key="studio_name"
                :columns="studios"
                @confirm="onConfirmStudio"
                @cancel="showStudio = false"
            />
        </van-popup>
        <van-popup v-model="showTeacher" :style="{ height: '65%' }" position="bottom" round>
            <van-picker
                show-toolbar
                :columns="teachers"
                value-key="filter_name"
                @cancel="showTeacher = false"
                @confirm="onConfirmTeacher"
            />
        </van-popup>
        <van-popup v-model="showAge" position="bottom" round>
            <van-picker
                show-toolbar
                :columns="ages"
                value-key="label"
                @cancel="showAge = false"
                @confirm="onConfirmAge"
            />
        </van-popup>

        <van-popup v-model="showDropStudio" position="bottom" :style="{ height: '80%' }" round>
            <van-tree-select
                height="154.5vw"
                :items="areaList"
                :active-id.sync="areaActiveId"
                :main-active-index.sync="areaActiveIndex"
                @click-nav="onClickNav"
                @click-item="onAreaClicked"
            />
        </van-popup>
        <!-- 底部导航栏 -->
        <copyright />
        <Tabbar v-show='!loading' />
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { Grid, GridItem, ActionSheet, Dialog, Tag, Picker, Empty, NoticeBar, Toast, TreeSelect, Checkbox, CheckboxGroup, Sticky, Progress} from 'vant'
    import dayjs from 'dayjs'
    import Schedule from './schedule'
    import Loadings from '@/components/loading'
    import Tabbar from '@/components/Tabbar'
    import Copyright from '@/components/Copyright'
    export default {
        name:"appointment",
        data() {
            return {
                studios: [],
                studio_id:'',
                studioValue:{},
                areaList:[],
                areaActiveIndex:'',
                areaActiveId:'',
                showStudio:false,
                showDropStudio:false,
                teachers: [],
                teacher_id:[],
                teacherValue:{},
                showTeacher:false,
                defaultDatetime:new Date(dayjs().year(), dayjs().month(), dayjs().date()),
                active:{date:dayjs().format('YYYY-MM-DD')},
                times:dayjs().format('YYYY-MM-DD'),
                courses:[],
                showAge:false,
                age_id:'',
                ageValue:{label:'全部年龄',value:''},
                ages:[
                    {label:'全部年龄',value:''},
                    {label:'L1',value:'L1'},
                    {label:'L2',value:'L2'},
                    {label:'L3',value:'L3'},
                ],
                activeBar:"-1",
                isRefresh:0,
                firstIn:1,
                latitude:'',
                longitude:'',
                isShowWeekView:true,
                isShowWeekViews:true,
                minDate: dayjs().toDate(),
                maxDate: dayjs().toDate(),
                markDate:[],
                appointmentClose:'',
                checkboxLevelGroup:[],
                checkboxGroup:[],
                forenoon:1,
                afternoon:1,
                evening:1,
                book_id:this.$route.query.book_id
            }
        },
        components: {
            Copyright,
            [Dialog.name]:Dialog,
            [Sticky.name]:Sticky,
            [Toast.name]: Toast,
            [Checkbox.name]:Checkbox,
            [CheckboxGroup.name]:CheckboxGroup,
            [Tag.name]:Tag,
            [Progress.name]:Progress,
            [TreeSelect.name]:TreeSelect,
            [Empty.name]: Empty,
            [Picker.name]:Picker,
            [NoticeBar.name]:NoticeBar,
            loadings:Loadings,
            Tabbar:Tabbar,
            schedule: Schedule,
        },
        computed: {
        },
        created () {
            if(dayjs().hour()>=20){
                this.defaultDatetime = new Date(dayjs().year(), dayjs().month(), dayjs().date()+1)
                this.times = dayjs().add('1','day').format('YYYY-MM-DD')
            }
            this.appointmentClose = localStorage.getItem('appointmentClose',1)
            this.loading = true
            this.getStudio()
            this.loading = false
        },
        activated() {
            this.getCourses()
        },
        methods: {
            dayjs,
            handleClick(name,num){
                this[name] = true
            },
            handleCheckbox(e){
                this.getCourses()
            },
            onScheduleChange(value) {
                this.times = value.date
                this.getCourses()
            },
            onClickNav(data){},
            onAreaClicked(data) {                
                this.studio_id = data.id;
                this.studioValue = {
                    studio_name:data.text,
                    studio_id:data.id,
                }
                this.showDropStudio=false
                this.getTime(this.studio_id)
            },
            course_book(item){
                if(!item.course_can_book){
                    Toast('选课展示,无法预约。');
                }
            },
            getLocation(){
                wx.ready(function () {
                    wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        // res 中返回经度和纬度，可以根据需求是否转换为QQ地图的精度
                        this.latitude = res.latitude
                        this.longitude = res.longitude
                    },
                    cancel: function (res) {
                        // console.log('用户拒绝授权获取地理位置');
                    },
                    fail: function (res) {
                        // alert(JSON.stringify(res));
                    }
                });
                });
            }, 
            getTime(studio_id){
                this.firstIn++
                this.getMarkDate(studio_id)
                this.getTeacher()
                this.getCourses()
            },
            getStudio(){
                this.$api.custom_huidong_available_studios()
                .then(res=>{
                    this.studios = res.data
                    this.areaList = res.studio_district
                    this.showDropStudio = false
                    if(res.current_studio){
                        this.studioValue = res.current_studio
                        this.studio_id = res.current_studio.studio_id
                        this.getTime(this.studio_id)
                        console.log(this.studioValue)
                    }else{
                        if(this.studios.length>0){
                            this.studioValue = this.studios[0]
                            this.studio_id = this.studioValue.studio_id
                            this.getTime(this.studio_id)
                        }
                    }
                })
            },
            getMarkDate(studio_id){
                this.$api.custom_huidong_available_schedule_date({studio_id:studio_id})
                .then(res=>{
                    console.log(res)
                    let markDate = []
                    res.mark_date.forEach(item=>{
                        markDate.push(dayjs(item).format('YYYY/MM/DD'))
                    })
                    this.markDate = [{color: '#ecbe4b',date: markDate}]

                })
            },
            getTeacher(){
                this.$api.custom_huidong_campus_teacher({studio_id:this.studio_id})
                .then(res=>{
                    this.teachers = res.data
                    this.teachers.unshift({filter_name:'全部',teacher_id:''})
                    this.teacherValue = {filter_name:'全部',teacher_id:''}
                })
            },
            getCourses(){
                this.loading = true
                let obj = {
                    studio_id:this.studio_id,
                    teacher_id:this.teacher_id,
                    start_date:this.times,
                    end_date:this.times,
                    age_id:this.age_id,
                    level_range:this.checkboxLevelGroup,
                    time_range:this.checkboxGroup
                }
                this.$api.custom_huidong_campus_schedule(obj)
                .then(res=>{
                    if(res.data.can_book==0){
                        Toast(this.times.substr(5,5)+'课程暂未开放,无法预约。');
                        this.courses = []
                        this.loading = false
                    }else{
                        this.courses = res.data.schdule_list
                        this.loading = false
                    }
                })
            },
            onConfirmStudio(value){
                this.studioValue = value
                this.studio_id = value.studio_id
                this.getTime(this.studio_id)
                this.showStudio = false
            },
            onConfirmTeacher(value){
                this.teacherValue = value
                this.teacher_id = value.teacher_id
                this.getCourses()
                this.showTeacher = false
            },
            onConfirmAge(value){
                this.ageValue = value
                this.age_id = value.value
                this.getCourses()
                this.showAge = false
            },
            toPath(course_class_id,it){
                if(it.course_can_book){
                    if(it.reserve_count >=it.table_count){
                        Dialog.confirm({
                            title: '等位预约确认',
                            message: '当前预约为等位预约，开课前3小时，未候补成功将自动取消候补预约。',
                        })
                        .then(() => {
                            this.$api.click_index({event_id:0,studio_id:0,event_type:'appointment_waitlist_btn'}).then(res=>{})
                            this.book_id?this.$router.push({path:'/huidong/appointment-apply',query:{course_class_id,book_id:this.book_id}}):this.$router.push({path:'/huidong/appointment-apply',query:{course_class_id}})
                        })
                        .catch(() => {
                            // on cancel
                        });
                    }else{
                        this.book_id?this.$router.push({path:'/huidong/appointment-apply',query:{course_class_id,book_id:this.book_id}}):this.$router.push({path:'/huidong/appointment-apply',query:{course_class_id}})
                    }
                }
            },
            handleClick(e){},
            handleChange(e){
                if(this.firstIn === 1) return false
                this.active = e
                this.times = e
                this.getCourses()
            },
            handleSlidechange(e){},
            isShowWeeks(){
                this.isShowWeekViews = !this.isShowWeekViews
                localStorage.setItem('isShowWeekViews',this.isShowWeekViews)
            },
            handleClose(){
                localStorage.setItem('appointmentClose',1)
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to.path === '/huidong/appointment-apply') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
                this.$store.commit('app/updateCatchComponents', ['appointment'])
            } else {
                this.$store.commit('app/updateCatchComponents', [])
            }
            next()
        },
    }
</script>

<style lang="less" scoped>
    .section{
        background: #fff;
        &-row{
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        }
        &-studio{
        box-sizing: border-box;
        width: 110px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        border: 1px solid #eee;
        }
        &-teacher{
        margin-left: 5px;
        box-sizing: border-box;
        width: 110px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        border: 1px solid #eee;
        }
        &-age{
        margin-left: 5px;
        box-sizing: border-box;
        width: 110px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        border: 1px solid #eee;
        }
    }
     .appointment_schedule_item{
        position: relative;
        &_btn{
            // width: 50px;
            position: absolute;
            bottom: 8px;
            right: 15px;
            font-size: 12px;
        }
        &_mark{
            position: absolute;
            top: 0;
            left: 0;
            img{
                width: 40px;
            }
            &_text{
                width: 28px;
                transform: rotate(-45deg);
                position: absolute;
                top: 7px;
                left: 1px;
                color: #fff;
                font-size: 9px;
                text-align: center;
            }
            &_textF{
                width: 30px;
                transform: rotate(-45deg);
                position: absolute;
                top: 7px;
                color: #fff;
                font-size: 9px;
                text-align: center;
            }
        }
        .custom-title {
            width: 240px;
            display: block;
        }
        .van-cell{
            position: relative;
            padding: 8px;
        }
        .van-cell-group{
            width: 96%;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;
        }
        .van-cell__title{
            flex: 4;
        }
        .van-cell__label{
            display: flex;
            font-size: 11px;
            align-items: baseline;
        }
    }
    .campus_schedule_search{
            .van-cell__value{
                flex: 2;
                min-width: 80%;
                span {
                    display: inline-block;
                    text-align: left;
                    word-break: break-all;
                }
            }
            .van-checkbox--horizontal{
                margin-right: 0;
                margin-left: 12px;
            }
            .van-checkbox-group--horizontal{
                justify-content: flex-end;
            }
        }
        .van-cell-group{
            width: 95%;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;
        }
        .campus_schedule_item{
            .van-cell__title{
                flex: 3;
            }
            .van-cell__label{
                display: flex;
                align-items: center;
            }
            &-img{
                width: 20px;
                position: absolute;
                right: 15px;
            }
        }
</style>
<style lang="less">
    .appointment_schedule{
        .calendar_content{
            overflow: initial;
        }
        .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
            background:#00cca2;
            color:#fff !important;
        }
        .calendar_first_today{
            color:#00cca2;
        }
    }
</style>