<template>
  <div class="schedule-wrapper">
    <div class="schedule">
      <van-sticky>
      <div>
        <van-tabs class="invited-van-tabs" @click="handleTabs" v-model="activeIndex">
          <van-tab v-for='(item,index) in list' :key='index'>
            <template #title>
              <div class='invited-van-tab'>
                <div v-if="dayjs(item.date).format('d') == 1">周一</div>
                <div v-if="dayjs(item.date).format('d') == 2">周二</div>
                <div v-if="dayjs(item.date).format('d') == 3">周三</div>
                <div v-if="dayjs(item.date).format('d') == 4">周四</div>
                <div v-if="dayjs(item.date).format('d') == 5">周五</div>
                <div v-if="dayjs(item.date).format('d') == 6">周六</div>
                <div v-if="dayjs(item.date).format('d') == 0">周日</div>
                <div class="invited-van-tab-date">{{dayjs(item.date).format("MM/DD")}}</div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </div>
      </van-sticky>
      <slot />
    </div>
    <van-calendar v-model="show" :default-date="defaultDate" :color="globalData.theme.color" :min-date="minDate" :max-date="maxDate" :show-confirm="false" @select="onSelect"/>
  </div>
</template>

<script>
import { Calendar, Loading, Tab, Tabs, Sticky, Popup, Picker} from 'vant'
import dayjs from 'dayjs'
export default {
  name: 'schedule',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Calendar.name]: Calendar,
    [Loading.name]: Loading,
    [Sticky.name]: Sticky,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  model: {
    prop: 'active',
    event: 'change'
  },
  props: {
    active: {
      type: Object,
      default: dayjs().format('YYYY-MM-DD')
    },
    invitedList:Object
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show: false,
      classList: [],
      courses: [],
      dotList:[],
      defaultDate: dayjs().toDate(),
      minDate: dayjs(dayjs().subtract(2, 'month')).startOf('month').toDate(),
      maxDate:dayjs(dayjs().add(2, 'month')).endOf('month').toDate(),
      activeIndex:0,
      pickerIndex:0
    }
  },
  computed: {
    globalData() {
      return this.$store.getters.globalData
    }
  },
  created(){
    this.list = this.getDiffDate(dayjs().add(-7,'day').format('YYYY-MM-DD'),dayjs().endOf('month').add(30,'day').format('YYYY-MM-DD'))
    this.list.forEach((item,index)=>{
      if(item.date === dayjs().format('YYYY-MM-DD')){
        this.activeIndex  = index
      }
    })
  },
  methods: {
    dayjs,
    handleTabs() {
      let item = this.list[this.activeIndex]
      this.$emit('change', item)
    },
    async onSelect(date) {
      this.show = false
      let value = dayjs(date).format('YYYY-MM-DD')
      this.list = this.getDiffDate(dayjs(value).startOf('month').format('YYYY-MM-DD'),dayjs(value).endOf('month').format('YYYY-MM-DD'))
      let obj = {}
      await this.list.forEach((item,index)=>{
        if(item.date == value){
          obj = item
          this.activeIndex = index
        }
      })
      this.$emit('change', obj)
    },
    onCalendarOpen() {
      this.show = true
    },
    getDiffDate(start, end) {
      var startTime = this.getDate(start)
      var endTime = this.getDate(end)
      var dateArr = []
      while ((endTime.getTime() - startTime.getTime()) >= 0) {
          var year = startTime.getFullYear()
          if(startTime.getMonth() == 9){
            var month = startTime.getMonth().toString().length === 1 ? (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1)
          }else{
            var month = startTime.getMonth().toString().length === 1 ? "0" + (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1)
          }
          var day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate()
          dateArr.push({date:year + "-" + month + "-" + day})
          startTime.setDate(startTime.getDate() + 1)
      }
      return dateArr
    },
    getDate (datestr) {
      var temp = datestr.split("-")
      if (temp[1] === '01') {
          temp[0] = parseInt(temp[0],10) - 1
          temp[1] = '12'
      } else {
          temp[1] = parseInt(temp[1],10) - 1
      }
      var date = new Date(temp[0], temp[1], temp[2])
      return date
    },
  },
}
</script>
<style lang="less" scoped>

  .schedule{
    &-header{
      display: flex;
      align-items: center;
      justify-items: flex-start;
      padding:0 15px;
      background: #fff;
      &-bd{
        flex: 1;
        height: 44px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
      }
    }
    &-weekdays{
      display: flex;
    }
    &-weekday{
      flex: 1;
      width: 14.2%;
      font-size: 12px;
      text-align: center;
      &-hd{
        position: relative;
        line-height: 30px;
      }
      &-activebg{
        background: #00DE93;
        color: #fff;
      }
      &-bd{
        padding: 0 0 10px;
        &-txt{
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          border-radius: 50%;
          position: relative;
          margin: 0 auto;
          &-dot{
            position: absolute;
            bottom: -6px;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #000;
          }
          &-dotbg{
            position: absolute;
            bottom: -6px;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #00DE93;
          }
        }
      }
    }
    .active{
      // background: #00DE93;
      color: #00DE93;
    }
  }

  .date-dot{
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    background-color: #ee0a24;
    border-radius: 100%;
  }

   .no-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
  .invited-van-tab{
    height: 40px;
    div{
      text-align: center;
    }
    .invited-van-tab-date{
      font-size: 12px;
    }
  }
</style>
<style lang="less">
  .invited-van-tabs{
    .van-info{
      top:4px
    }
  }
  .schedule-wrapper .van-calendar__bottom-info{
    font-size: 32px;
    color: #ee0a24;
  }
  .schedule{
    .van-tabs{
      z-index: 100;
    }
  }
</style>

